import axios from 'axios'
import api from './api'

import snackbarPlugin from '@/plugins/snackbar'
import TokenService from './token.service'

const baseURL = process.env.VUE_APP_DOMAIN

function authAuthorization(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

axios.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    // handle error
    if (error.response) {
      // this.$store.dispatch('auth/logout')
      snackbarPlugin.showError(error.response.data.detail)
    }
    return error
  }
)

class SubscriptionsService {
  async callToAWSMarketplace(data) {
    const response = await axios.post(
      'https://bxrhhqlt28.execute-api.us-east-1.amazonaws.com/Prod/subscriber', data, { withCredentials: false }
    )
    return response
  }

  async getCheckSubscription() {
      const response = await api.get('/api/v1/subscriptions/?isAvailable=true')
      return response.data
    }

    async getCheckSubscriptionByClientId(clientId) {
      const response = await api.get(`/api/v1/subscriptions/${clientId}`)
      return response
    }

    async postCheckSubscription(data) {
        const response = await axios.post(
          `${baseURL}/api/v1/subscriptions/azure/registration-access`, data
        )
        return response
    }

    async postNewClientAzure(data, registrationToken) {
        const response = await axios.post(
          `${baseURL}/api/v1/subscriptions/azure/register-client`, data, authAuthorization(registrationToken)
        )
        return response
    }

    async createClientAWS(data, registrationToken) {
      const response = await axios.post(
        `${baseURL}/api/v1/subscriptions/aws/register-client`, data, authAuthorization(registrationToken)
      )
      return response
  }

    async validateSubscription(data) {
        const response = await axios.post(`${baseURL}/api/v1/subscriptions/azure/activate`, data)
        if (response.data) {
          TokenService.setUser(response.data)
          if (response.data.pin_token !== 'null') {
            localStorage.pinToken = response.data.pin_token
          }
        } else {
          window.location = "/"
          return response
        }
        return response
    }
}

export default new SubscriptionsService()
