<template>
  <div class="px-1">
    <create-pentest-dialog
      v-model="showAskPentest"
      v-on:reload-items="refreshPentests"
    />

    <schedule-pentest-dialog
      v-if="haveAvablePlan"
      :show-schedule-pentest-dialog.sync="showSchedulePentestDialog"
      v-on:reload-items="initializeData"
      :subscriptions-plans="subscriptionsPlans"
    />

    <buy-more-pentest-dialog
      v-if="!haveAvablePlan"
      :show-schedule-pentest-dialog.sync="showSchedulePentestDialog"
      :subscriptions-plans="subscriptionsPlans"
    />

    <v-row class="mt-0 pt-0">
      <v-col cols="12" :md="12" class="mt-0 pt-0">
        <v-toolbar color="background" class="mb-1 mt-8 px-0" flat rounded="lg">
          <v-icon
            large
            color="primary"
            class="text-36 mr-2 d-none d-sm-block"
          >
            mdi-rocket-launch-outline
          </v-icon>
          <v-col cols="7" sm="3" md="2" lg="2" class="pl-0 pl-sm-3">
            <v-text-field
              v-model="filterSearch"
              hide-details
              label="Search"
              placeholder="Search"
              solo
              flat
              background-color="grey"
              dense
              single-line
              append-icon="mdi-magnify"
              class="shrink"
            />
          </v-col>
          <!--<v-col v-if="isSuperUser" cols="2" md="2" lg="2" class="d-none d-sm-inline">
            <v-select
              v-model="filterClient"
              label="Client"
              :items="itemsClient"
              item-text="name"
              item-value="id"
              solo
              flat
              background-color="grey"
              dense
              single-line
              hide-details
              class="shrink"
            />
          </v-col>
          <v-col cols="2" md="2" lg="2" class="d-none d-sm-inline">
            <v-select
              v-model="filterType"
              label="Type"
              :items="pentestType"
              solo
              flat
              background-color="grey"
              dense
              single-line
              hide-details
              class="shrink"
            />
          </v-col>
          <v-col cols="2" md="2" lg="2" class="d-none d-sm-inline">
            <v-select
              v-model="filterStatus"
              label="Status"
              :items="status"
              solo
              flat
              background-color="grey"
              dense
              single-line
              hide-details
              class="shrink"
            />
          </v-col>
          <v-col cols="2" md="2" lg="2" class="d-none d-sm-inline">
            <v-select
              v-model="filterAsset"
              label="Asset"
              :items="itemsAsset"
              item-text="name"
              item-value="id"
              solo
              flat
              background-color="grey"
              dense
              single-line
              hide-details
              class="shrink"
            />
          </v-col>-->
          <div class="text-center">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-1"
                  elevation="0"
                  color="grey"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-badge
                    :value="showBadge()"
                    color="primary"
                    overlap
                    dot
                  >
                    <v-icon>mdi-filter-outline</v-icon>
                  </v-badge>
                </v-btn>
              </template>

              <v-card>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Filter</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-divider />

                <v-list>
                  <v-list-item>
                    <v-select
                      v-model="filterStatus"
                      label="Pentest status"
                      :items="status"
                      solo
                      flat
                      background-color="grey"
                      dense
                      style="max-width:237px"
                      single-line
                      clearable
                      hide-details
                      class="shrink"
                    />
                  </v-list-item>

                  <v-list-item>
                    <v-select
                      v-model="filterType"
                      label="Pentest type"
                      :items="phasesOptions"
                      solo
                      flat
                      background-color="grey"
                      style="max-width:237px"
                      dense
                      clearable
                      single-line
                      hide-details
                      class="shrink"
                    />
                  </v-list-item>

                  <v-list-item v-if="isSuperUser">
                    <async-search-select
                      v-model="filterClient"
                      label="Client"
                      :items="itemsClient"
                      item-text="name"
                      :items-update="fetchClientRefs"
                    />
                  </v-list-item>

                  <v-list-item>
                    <async-search-select
                      ref="assetSearch"
                      v-model="filterAsset"
                      label="Asset"
                      :items="itemsAsset"
                      item-text="name"
                      :items-update="fetchAssetRefs"
                      :is-disabled="isSuperUser && filterClient === null"
                    />
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
          <v-tooltip
            v-if="showClearFilterButton"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="grey"
                class="mx-2"
                fab
                small
                :elevation="0"
                @click="clearFilter"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">mdi-close-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>Clear Filters</span>
          </v-tooltip>
          <v-spacer />
          <span v-if="isClientAdmin" class="ml-auto">
            <v-btn
              v-if="isSuperUser || isClientAdmin && !isSubs"
              color="primary"
              class="mr-1"
              depressed
              @click.native="showAskPentest = true"
            >
              <v-icon class="mr-sm-2">mdi-rocket-launch-outline</v-icon>
              <span class="d-none d-sm-block">New Pentest</span>
            </v-btn>
            <v-btn
              v-if="isClientAdmin && isSubs"
              color="primary"
              class="mr-1"
              depressed
              @click.native="showSchedulePentestDialog = true"
            >
              <v-icon class="mr-sm-2">mdi-rocket-launch-outline</v-icon>
              <span class="d-none d-sm-block">New Pentest</span>
            </v-btn>
          </span>
        </v-toolbar>
        <pentest-table
          ref="pentestTable"
          :filter-search.sync="filterSearchUpdate"
          :filters.sync="pentestFilters"
          :set-client="setClient"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h6"> Retest </v-card-title>
        <v-card-text>You want to perform a retest ?</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text outlined @click="dialog = false"> Cancel </v-btn>
          <v-btn
            color="success"
            class="white--text"
            depressed
            @click="performRetest"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PentestTable from '@/components/table/PentestTable'
import ClientService from '@/services/client.service'
import AssetService from '@/services/asset.service'
import SubscriptionsService from '@/services/subcriptions.service'
import CreatePentestDialog from '@/components/dialog/pentests/CreatePentestDialog.vue'
import SchedulePentestDialog from '@/components/dialog/pentests/SchedulePentestDialog.vue'
import BuyMorePentestDialog from '@/components/dialog/pentests/BuyMorePentestDialog.vue'
import { phasesOptions, status } from '@/data/common'
import AsyncSearchSelect from '@/components/base/AsyncSearchSelect.vue'
import UserService from '@/services/user.service'

export default {
  name: 'Pentests',
  metaInfo: {
    title: 'Pentests'
  },
  components: {
    AsyncSearchSelect,
    PentestTable,
    CreatePentestDialog,
    SchedulePentestDialog,
    BuyMorePentestDialog
  },
  data: () => ({
    isClientAdmin: false,
    isAdmin: false,
    isSuperUser: false,
    dialog: false,
    filterSearch: null,
    filterStatus: null,
    filterType: null,
    filterClient: null,
    filterAsset: null,
    showAskPentest: false,
    menu: false,
    phasesOptions,
    status,
    itemsClient: [],
    itemsAsset: [],
    pentests: [],
    showSchedulePentestDialog: false,
    subscriptionsPlans: null,
    isSubs: false,
    haveAvablePlan: false
  }),
  computed: {
    showClearFilterButton() {
      return !!(this.filterSearch || this.filterStatus || this.filterType || this.filterClient || this.filterAsset)
    },
    pentestFilters() {
      return {
        status: this.filterStatus,
        phase: this.filterType,
        assetID: this.filterAsset ? this.filterAsset.id : null,
        clientID: this.filterClient ? this.filterClient.id : null
      }
    },
    filterSearchUpdate() {
      if (this.filterSearch !== null) {
        this.$refs.pentestTable.searchAfterWait()
        return this.filterSearch
      }
    }
  },
  watch: {
    '$store.state.initializeData.refresh': {
      handler() {
        this.refreshing()
      },
      immediate: true
    },
    filterClient: {
      handler() {
        if (this.filterClient !== null) {
          this.fetchAssetRefs('')
        } else {
          this.filterAsset = null
        }
      }
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isSuperUser = profile.role === 'Superuser'
      this.isAdmin = profile.role !== 'Client User'
      this.isClientAdmin = profile.role === 'Client Admin' || this.isSuperUser

      if (profile.client !== null) {
        this.isSubs = profile.client.subscriptionSource === 'azure'
      }
      this.initializeData()
      this.$store.dispatch('changeThemeLoadingState', false)
    }
  },
  methods: {
    async checkClientSubscriptions() {
      this.subscriptionsPlans = await SubscriptionsService.getCheckSubscription()
      this.haveAvablePlan = this.subscriptionsPlans.total > 0;
    },
    showBadge() {
      if (this.filterStatus || this.filterType || this.filterClient || this.filterAsset) {
        return true
      } else {
        return ''
      }
    },
    refreshing() {
      if (this.$store.state.initializeData.refresh === true) {
        this.initializeData()
        this.$store.dispatch('initializeData/refreshData', false)
      }
    },
    refreshPentests() {
      this.$refs.pentestTable.fetchPentests()
    },
    initializeData() {
      if (this.isClientAdmin) {
        this.checkClientSubscriptions()
      }

      if (this.$route.query.clientId) {
        this.filterClient = { id: this.$route.query.clientId }
      }
      this.$store.dispatch('changeThemeLoadingState', false)
    },
    async fetchClientRefs(search) {
      ClientService.getClientRefs(1, 10, search).then((resp) => {
        this.itemsClient = resp.results
      })
    },
    async fetchAssetRefs(search) {
      AssetService.getAssetRefs(
        1, 10, search, { clientID: this.filterClient ? this.filterClient.id : null }
      ).then((resp) => {
        this.itemsAsset = resp.results
      })
    },
    performRetest() {
      this.dialog = false
    },
    setClient(client) {
      this.itemsClient = [client]
      this.filterClient = client
    },
    clearFilter() {
      this.filterSearch = ''
      this.filterStatus = null
      this.filterType = null
      this.filterClient = null
      this.filterAsset = null

      if (this.$route.query.clientId) {
        this.$router.push(`/pentests/`)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
table.v-table tbody td,
table.v-table tbody th {
  min-height: 50px !important
}

.v-stepper,
.v-stepper__header {
  box-shadow: none !important
}
.v-select__selection{
  font-weight: bold;
}
</style>
