<template>
  <delete-dialog
    item-type="Pentest"
    :show-delete-dialog.sync="showDeleteDialog"
    v-on:close="close"
  >
    <template v-slot:actions>
      <v-btn
        color="error darken-1"
        elevation="0"
        @click.native="close"
        @keyup="close"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary lighten-1"
        outlined
        @click.native="confirm"
      >
        Delete
      </v-btn>
    </template>
  </delete-dialog>
</template>

<script>
import PentestService from '@/services/pentest.service'
import snackbarPlugin from '@/plugins/snackbar'
import DeleteDialog from '@/components/dialog/DeleteDialog'

export default {
  components: {
    DeleteDialog
  },
  props: {
    showDeleteDialog: {
      type: Boolean,
      required: true
    },
    editedItem: {
      type: Object,
      default: null
    }
  },
  data: () => {
    return {}
  },
  mounted() {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.close()
      }
    })
  },
  methods: {
    close() {
      this.$emit('update:show-delete-dialog', false)
    },
    confirm() {
      snackbarPlugin.showLoading('Deleting ...')
      PentestService.deletePentest(this.editedItem.id).then((resp) => {
        if (resp.status === 204) {
          snackbarPlugin.showSuccess('Pentest deleted!')
          this.$emit('reload-items')
        } else {
          snackbarPlugin.showError(resp.data.detail)
        }
      }).catch((error) => {})
      this.close()
    }
  }
}
</script>
