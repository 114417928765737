<template>
  <base-dialog
    :show="showSchedulePentestDialog"
    form-title="New Penetration Test"
    size="665px"
    :hide-close-button="true"
    icon="mdi-rocket-launch-outline"
    text-btn2="Schedule"
    v-on:close="close"
    v-on:save="save"
  >
    <template v-slot:form>
      <div class="ul-icon-box mx-auto mb-3">
        <div class="bg-group ul-animate fallingClouds" />
        <v-img
          height="80"
          width="80"
          class="mx-auto"
          src="@/assets/images/svg/startup.svg"
        />
      </div>
      <div class="text-center mb-6">
        <h4 class="font-weight-bold py-0 my-0">Let's get started!</h4>
        <p class="text--lighten-1 py-0 my-0"></p>
      </div>

      <v-form ref="form" v-model="valid" lazy-validation>
        <!--<v-list-item-content class="text-left pt-0 mt-0" style="width: 100%">
          <div class="text-left primary--text text--lighten-2 font-weight-bold">
            SUBSCRIPTION <span class="h6 primary--text text--lighten-2">*</span>
          </div>
        </v-list-item-content>
        <v-select
          v-model="subscription"
          label="Select your subscription"
          :rules="[v => !!v || 'This field is required']"
          :items="subscriptions"
          return-object
          cache-items
          dense
          flat
          outlined
          @input="selectSubscription"
          @click="fetchSubscriptions"
        />-->
        <v-list-item-content class="text-left pt-0 mt-0" style="width: 100%">
          <div class="text-left primary--text text--lighten-2 font-weight-bold">
            PROJECT TYPE <span class="h6 primary--text text--lighten-2">*</span>
          </div>
        </v-list-item-content>
        <v-select
          v-model="pentestsType"
          label="Select your subscription"
          :rules="[v => !!v || 'This field is required']"
          :items="simplePentestType"
          dense
          flat
          outlined
          @input="selectRequirements"
          @click="fetchSubscription"
        />

        <v-list-item-content class="text-left pt-0 mt-0" style="width: 100%">
          <div class="text-left primary--text text--lighten-2 font-weight-bold">
            PROJECT TITLE <span class="h6 primary--text text--lighten-2">*</span>
          </div>
        </v-list-item-content>
        <v-text-field
          v-model="pentestTitle"
          label="Project title"
          outlined
          dense
          :rules="[v => !!v || 'This field is required']"
        />

        <v-list-item-content class="text-left pt-0 mt-0" style="width: 100%">
          <div class="text-left primary--text text--lighten-2 font-weight-bold">
            REQUIREMENTS <span class="h6 primary--text text--lighten-2">*</span>
          </div>
        </v-list-item-content>
        <v-textarea
          v-model="pentestRequirements"
          solo
          :rules="[v => !!v || 'This field is required']"
          danse
          rows="4"
          flat
          :placeholder="requirementsPlaceholder ? requirementsPlaceholder : 'Provide testing requirements'"
          :disabled="!requirementsPlaceholder"
          outlined
        />
        <v-list-item-content class="text-left pt-0 mt-0" style="width: 100%">
          <div class="text-left primary--text text--lighten-2 font-weight-bold">
            NOTES <small class="font-italic font-weight-thin">(Optional)</small>
          </div>
        </v-list-item-content>
        <v-textarea
          v-model="pentestNotes"
          solo
          danse
          rows="4"
          placeholder="Additional information that we should know"
          flat
          outlined
        />
        <v-list-item-content class="text-left pt-0 mt-0" style="width: 100%">
          <div class="text-left primary--text text--lighten-2 font-weight-bold">
            FILE ATTACHMENT <small class="font-italic font-weight-thin">(Optional)</small>
          </div>
        </v-list-item-content>
        <v-file-input
          v-model="pentestFiles"
          outlined
          dense
          prepend-icon
          danse
          drag-drop
          label="Select file here..."
          flat
          @change="uploadFile"
        />
      </v-form>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
import snackbarPlugin from '@/plugins/snackbar'
import FilesService from '@/services/files.service.js'
import PentestService from '@/services/pentest.service'
import SubscriptionsService from '@/services/subcriptions.service'
import { subscriptionPlans, simplePentestType } from '@/data/common'

export default {
  components: {
    BaseDialog
  },
  props: {
    showSchedulePentestDialog: {
      type: Boolean
    },
    subscriptionsPlans: { // why is this prop exist if not used?
      type: Object,
      required: true
    }
  },
  data: () => ({
    valid: true,
    pentestSubId: null,
    subscription: null,
    subscriptions: [],
    pentestPlanType: [],
    pentestTitle: null,
    pentestsType: null,
    pentestRequirements: null,
    pentestNotes: null,
    pentestFiles: null,
    clientId: null,
    fileId: null,
    isSuperUser: false,
    itemsClient: null,
    isAdmin: false,
    simplePentestType,
    requirementsPlaceholder: ''
  }),
  mounted() {},
  methods: {
    selectSubscription(selection) {
      if (selection) {
        const planId = selection.planId
        if (planId.startsWith('webapp')) {
          this.requirementsPlaceholder = 'Application URL and valid user account (username/password)'
          this.pentestTitle = 'Web application penetration test'
          this.pentestsType = 'webapp'
        } else if (planId.startsWith('web')) {
          this.requirementsPlaceholder = 'Website URL'
          this.pentestTitle = 'Website penetration test'
          this.pentestsType = 'webapp'
        } else {
          this.pentestTitle = 'External network penetration test'
          this.pentestsType = 'external'
          this.requirementsPlaceholder = 'IP range or IP list'
        }
      }
    },
    selectRequirements(selection) {
      if (selection) {
        const planId = this.pentestsType;
        if (planId.startsWith('app')) {
          this.requirementsPlaceholder = 'Application URL and valid user account (username/password)'
          this.pentestTitle = 'Web application penetration test'
          // this.pentestsType = 'app'
        } else {
          this.pentestTitle = 'External network penetration test'
          this.requirementsPlaceholder = 'IP range or IP list'
        }
      }
    },
    async fetchSubscriptions() {
      await SubscriptionsService.getCheckSubscription().then(resp => {
        this.subscriptions = []
        resp.results.forEach(subscription => {
          // const planId = subscription.content.planId;
          this.subscriptions.push({
            // text: `${subscription.createdAt.slice(0, 10)} - ${
            //   subscriptionPlans[planId].name
            // }`,
            value: subscription.id
            // planId: planId
          })
        })
      }).catch(() => {})
    },

    // Utilisé pour la vue qui utilise le project type
    async fetchSubscription() {
      await SubscriptionsService.getCheckSubscription().then(resp => {
        resp.results.forEach(subscription => {
          this.subscription = subscription.id
        })
      }).catch(() => {})
    },

    async uploadFile() {
      if (this.pentestFiles !== null) {
        const res = await FilesService.postUploadFile([this.pentestFiles]);
        snackbarPlugin.showSuccess(`File ${this.pentestFiles.name} uploaded!`)
        this.fileId = res.uuid
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        snackbarPlugin.showLoading('Creating ...')
        const resp = await PentestService.postQuickPentests({
          title: this.pentestTitle,
          subscriptionId: this.subscription,
          type: this.pentestsType,
          requirements: this.pentestRequirements,
          scopeOfWork: this.pentestNotes,
          files: this.fileId ? [this.fileId] : []
        }).then(() => {
          this.$store.dispatch('initializeData/refreshData', true)
          this.close()
          snackbarPlugin.showSuccess(
            'Your pentest request was successfully created!'
          )
        }).catch(error => {
          snackbarPlugin.showError(error.response.data.detail);
        })
        if (this.pentestFiles !== null) {
          await FilesService.postAddFileByPentest(resp.id, this.fileId);
        }
        this.close()
      } else {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    },
    close() {
      this.$refs.form.reset()
      this.$store.dispatch('initializeData/refreshData', true)
      this.$emit('update:showSchedulePentestDialog', false)
    }
  }
}
</script>
