<template>
  <div>
    <restest-dialog
      :edited-item="selectedItem"
      :show-retest-dialog.sync="showRetestDialog"
      v-on:reload-items="fetchPentests"
    />

    <delete-pentest-dialog
      :edited-item="selectedItem"
      :show-delete-dialog.sync="showDeleteDialog"
      v-on:reload-items="fetchPentests"
    />

    <v-card flat>
      <v-data-table
        :headers="headers"
        :items="pentests"
        :server-items-length="pentestCount"
        :loading="isLoading"
        :options.sync="options"
        :footer-props="{
          itemsPerPageOptions: pageSizeOptions
        }"
      >
        <template v-slot:item.icon="{ item }">
          <v-avatar color="grey" class="my-5 d-none d-sm-flex">
            <v-icon medium class="my-2">
              mdi-rocket-launch-outline
            </v-icon>
          </v-avatar>
        </template>

        <template v-slot:item.title="{ item }">
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold pointer primary--text text--lighten-2"
              @click="fullViewItem(item)"
            >
              {{ item.title }}
            </v-list-item-title>
            <v-list-item-subtitle
              @click="fullViewItem(item)"
            >
              <span v-if="item.startDate">Started on: {{ item.startDate }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <template v-slot:item.createdAt="{ item }">
          <div>
            {{ displayDate(item) }}
          </div>
        </template>

        <template v-slot:item.client="{ item }">
          <div
            v-if="item.client"
            class="font-weight-bold pointer primary--text text--lighten-2" @click="clientViewItem(item)"
          >
            {{ item.client.name }}
          </div>
        </template>
        <!--
        <template v-slot:item.lastRetest="{ item }">
          <div class="d-flex flex-wrap justify-center">
            <v-chip
              v-if="item.lastRetest !== null"
              outlined
              color="primary"
              class="text-capitalize mt-4"
            >
              {{ item.lastRetest.status }}
            </v-chip>
          </div>
          <div
            v-if="item.lastRetest !== null"
            class="d-flex flex-wrap justify-center"
          >
            <small>{{ item.lastRetest.startDate }}</small>
          </div>
        </template> -->

        <template v-slot:item.phaseNames="{ item }">
          <v-chip
            color="primary lighten-2"
            outlined
            class="text-capitalize my-2"
          >
            {{ item.phaseNames.length !== 0 ? setPhaseType(item.phaseNames[0]) : setPentestType(item.type) }}
          </v-chip>
          <v-tooltip bottom v-if="item.phaseNames.length > 1 ? item.phaseNames.length : false > 1">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color=""
                icon
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon> More
              </v-chip>
            </template>
            <span v-for="(phase, index) in item.phaseNames.filter((phase, i) => i !== 0)" :key="index">
              <div>{{ setPhaseType(phase) }}</div>
            </span>
          </v-tooltip>
        </template>

        <template v-slot:item.data="{ item }">
          <v-chip v-if="item.data">
            {{ item.data.numCritical + item.data.numHigh + item.data.numModerate + item.data.numLow }} Total
          </v-chip>
          <v-chip v-else>
            0 Total
          </v-chip>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip
            v-if="item.status === 'Created'"
            color="grey"
            class="my-5"
          >
            <span>Created</span>
          </v-chip>

          <v-chip
            v-if="item.status === 'Ready to Start'"
            color="grey"
          >
            <span>Ready to Start</span>
          </v-chip>

          <v-chip
            v-if="item.status === 'Testing'"
            color="grey"
            class="my-5"
          >
            <span>Testing</span>
          </v-chip>

          <v-chip
            v-if="item.status === 'Reporting'"
            color="grey"
            class="my-5"
          >
            <span>Reporting</span>
          </v-chip>

          <v-chip
            v-if="item.status === 'Report Ready'"
            color="grey"
            class="my-5"
          >
            <span>Report Ready</span>
          </v-chip>

          <v-chip
            v-if="item.status === 'Report Sent'"
            color="grey"
            class="my-5"
          >
            <span>Report Sent</span>
          </v-chip>

          <v-chip
            v-if="item.status === 'Done'"
            color="grey"
            class="my-5"
          >
            <span>Done</span>
          </v-chip>
        </template>

        <template v-slot:item.id="{ item }">
          <span class="d-none d-lg-flex">
            <v-spacer />
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isAdmin"
                  color="primary"
                  class=""
                  icon
                  large
                  aria-label="Retest"
                  :disabled="retestable(item)"
                  v-bind="attrs"
                  v-on="on"
                  @click="retestItem(item)"
                >
                  <v-icon>mdi-autorenew</v-icon>
                </v-btn>
              </template>
              <span>Ask for a Retest</span>
            </v-tooltip> -->
            <!--<v-btn
              color="primary"
              outlined
              depressed
              class="mx-2"
              fab
              small
              disabled
              :elevation="0"
              @click="downloadReport"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>-->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary lighten-2"
                  class=""
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  @click="fullViewItem(item)"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>View Details</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isSuperUser"
                  color="danger"
                  class=""
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
            <v-spacer />
          </span>
          <v-menu
            bottom
            left
            class="d-md-flex d-lg-none"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class="d-md-flex d-lg-none ml-14"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <!--<v-list-item
                v-if="isAdmin"
                :class="retestable(item) ? 'gray--text' : ''"
                link
                :disabled="retestable(item)"
                @click="retestItem(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>Retest</v-list-item-title>
                </v-list-item-content>
              </v-list-item>-->

              <v-list-item
                link
                class="primary--text text--lighten-2"
                @click="fullViewItem(item)"
              >
                <v-icon class="mr-2" color="primary lighten-2">mdi-magnify</v-icon>
                <v-list-item-content>
                  <v-list-item-title>View details</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="isSuperUser"
                link
                class="error--text"
                @click="deleteItem(item)"
              >
                <v-icon class="mr-2" color="error">mdi-delete-outline</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import RestestDialog from '@/components/dialog/pentests/RetestDialog.vue'
import DeletePentestDialog from '@/components/dialog/pentests/DeletePentestDialog.vue'
import { pentestType, phasesOptions, tagPhasesOptions } from '@/data/common'
import PentestService from '@/services/pentest.service'
import _ from 'lodash'
import UserService from '@/services/user.service'

export default {
  components: {
    RestestDialog,
    DeletePentestDialog,
    pentestType
  },
  props: {
    filterSearch: {
      type: String || undefined,
      default: ''
    },
    filters: {
      type: Object,
      required: true
    },
    setClient: {
      type: Function,
      required: true
    }
  },
  data: function() {
    return {
      showRetestDialog: false,
      selectedItem: {},
      showDeleteDialog: false,
      isAdmin: false,
      isSuperUser: false,
      isRetestable: true,
      pentestType,
      phasesOptions,
      tagPhasesOptions,
      search: this.$route.query.search || '',
      pentests: [],
      pentestCount: 0,
      pageSizeOptions: [5, 10, 25],
      isLoading: false,
      debounceSearch: null,
      options: {
        page: parseInt(this.$route.query.page || '1', 10) || 1,
        itemsPerPage: parseInt(this.$route.query.page_size || '5', 10) || 5,
        search: this.$route.query.search || '',
        phase: null,
        status: null,
        assetID: null,
        clientID: this.$route.query.clientId || null
      },
      previousCall: {
        page: parseInt(this.$route.query.page || '1', 10) || 1,
        itemsPerPage: parseInt(this.$route.query.page_size || '5', 10) || 5,
        search: this.$route.query.search || '',
        phase: null,
        status: null,
        assetID: null,
        clientID: this.$route.query.clientId || null
      },
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'icon',
          width: '20px',
          class: 'text-center font-weight-medium subtitle-1'
        },
        {
          text: 'Title',
          value: 'title',
          class: 'text-left font-weight-medium subtitle-1'
        },
        // Client section [Only for SU]
        {
          text: '',
          value: '',
          class: ''
        },
        // {
        //   text: 'Retest',
        //   value: 'lastRetest',
        //   align: 'center',
        //   class: 'text-center font-weight-medium subtitle-1',
        // },
        {
          text: 'Phases',
          value: 'phaseNames',
          align: 'center',
          sortable: false,
          class: 'text-center font-weight-medium subtitle-1'
        },
        {
          text: 'Vulnerabilities',
          value: 'data',
          sortable: false,

          align: 'center',
          class: 'text-center font-weight-medium subtitle-1'
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: false,
          width: '100px',
          class: 'text-center font-weight-medium subtitle-1'
        },
        {
          text: 'Created on',
          value: 'createdAt',
          width: '160px',
          class: 'text-left font-weight-medium subtitle-1'
        },
        {
          text: 'Actions',
          value: 'id',
          sortable: false,
          align: 'center',
          width: '140px',
          class: 'text-center font-weight-medium subtitle-1'
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchPentestsHandler()
      },
      deep: true
    },
    filters: {
      handler() {
        this.options.status = this.filters.status
        this.options.phase = this.filters.phase
        this.options.assetID = this.filters.assetID
        this.options.clientID = this.filters.clientID
      },
      deep: true
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isAdmin = profile.role !== 'Client User'
      if (profile.role === 'Superuser') {
        this.isSuperUser = true
        this.headers[2].text = 'Client'
        this.headers[2].value = 'client'
        this.headers[2].class = 'text-left font-weight-medium subtitle-1'
      } else {
        this.isSuperUser = false
      }
    }
    this.fetchPentests()
  },
  methods: {
    searchAfterWait() {
      this.search = this.filterSearch
      if (!this.debounceSearch) {
        this.debounceSearch = _.debounce(this.fetchPentestsHandler, 700)
      }
      this.debounceSearch()
    },
    fetchPentestsHandler() { // no change since last change
      const newCall = {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        search: this.search,
        phase: this.options.phase,
        status: this.options.status,
        assetID: this.options.assetID,
        clientID: this.options.clientID
      }
      if (
        newCall.page === this.previousCall.page
        && newCall.itemsPerPage === this.previousCall.itemsPerPage
        && newCall.search === this.previousCall.search
        && newCall.phase === this.previousCall.phase
        && newCall.status === this.previousCall.status
        && newCall.assetID === this.previousCall.assetID
        && newCall.clientID === this.previousCall.clientID
      ) {
        return
      }
      if (
        (
          newCall.itemsPerPage !== this.previousCall.itemsPerPage
          || newCall.search !== this.previousCall.search
          || newCall.phase !== this.previousCall.phase
          || newCall.status !== this.previousCall.status
          || newCall.assetID !== this.previousCall.assetID
          || newCall.clientID !== this.previousCall.clientID
        ) && this.options.page !== 1
      ) {
        this.options.page = 1 // reset to page 1 will retrigger the call
        return
      }
      this.previousCall = newCall
      this.fetchPentests()
    },
    fetchPentests() {
      this.isLoading = true
      const { page, itemsPerPage, phase, status, assetID, clientID } = this.options
      PentestService.getPentests(
        page, itemsPerPage, this.search, { phase: phase, status: status, assetID: assetID, clientID: clientID }
      ).then((pentests) => {
        this.pentests = pentests.results
        this.pentestCount = pentests.total
      }).catch(() => {})
      this.isLoading = false
    },
    setPhaseType(type) {
      for (let i = 0; i < this.phasesOptions.length; i++) {
        if (this.phasesOptions[i].value === type) {
          return this.phasesOptions[i].text
        }
      }
      for (let i = 0; i < this.tagPhasesOptions.length; i++) {
        if (this.tagPhasesOptions[i].value === type) {
          return this.tagPhasesOptions[i].text
        }
      }
      // handle non-hardcoded tag phase
      return type
    },
    setPentestType(type) {
      for (let i = 0; i < this.pentestType.length; i++) {
        if (this.pentestType[i].value === type) {
          return this.pentestType[i].text
        }
      }
    },
    retestable(item) {
      if (item.lastRetest !== null) {
        return item.lastRetest.status !== 'completed'
      }
      return item.status !== 'completed'
    },
    downloadReport() {
      this.$swal({
        text: 'Download has just started!',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok, got it!',
        customClass: {
          confirmButton: 'btn fw-bold btn-light-primary'
        }
      }).then(() => {
        this.dialog = false
      })
    },
    retestItem(item) {
      this.selectedItem = Object.assign({}, item)
      this.showRetestDialog = true
      this.$emit('reload-items')
    },
    displayDate(item) {
      return `${new Date(item.createdAt.slice(0, 16) + '+0000').toLocaleDateString('fr-CA').replace(/\//g, '-')}`
    },
    fullViewItem(item) {
      this.selectedItem = Object.assign({}, item)
      this.$router.push(`/pentest/detail/${item.id}`)
    },
    clientViewItem(item) {
      this.setClient(item.client)
    },
    deleteItem(item) {
      this.selectedItem = Object.assign({}, item)
      this.showDeleteDialog = true
    }
  }
}
</script>
