<template>
  <div>
    <base-dialog
      :show="showRetestDialog"
      form-title="Ask for a Retest"
      icon="mdi-autorenew"
      v-on:close="close"
      v-on:save="save"
    >
      <template v-slot:form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col
            cols="12"
            class="mb-6 py-0"
          >
            <div class="h5">Do you want to retest your pentest ?</div>
            <!--<div class="ml-4 font-weight-bold">
              <span> ➡</span>
              <span class="primary--text text--lighten-2"> {{ editedItem.title }}</span>
            </div>-->
          </v-col>
          <v-col
            cols="12"
            class="my-0 py-0"
          >
            <v-list-item-content
              class="text-left my-0"
              style="width: 100%"
            >
              <div class="text-left primary--text text--lighten-2 font-weight-bold">
                TIMELINE <span class="h6 primary--text text--lighten-2">*</span>
              </div>
            </v-list-item-content>
            <v-menu
              v-model="menuStartDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="retestStartDate"
                  :rules="[v => !!v || 'This field is required']"
                  label="Retest start date"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="retestStartDate"
                :min="minDate"
                @input="menuStartDate = false"
              />
            </v-menu>
            <v-menu
              v-model="menuEndDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="retestEndDate"
                  label="Retest expected end date"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="retestEndDate"
                :min="minDate"
                @input="menuEndDate = false"
              />
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            class="my-0 py-0"
          >
            <v-list-item-content
              class="text-left my-0"
              style="width: 100%"
            >
              <div class="text-left primary--text text--lighten-2 font-weight-bold">
                COMMENT <span class="h6 primary--text text--lighten-2">*</span>
              </div>
            </v-list-item-content>
            <v-textarea
              v-model="comments"
              placeholder="Enter a brief reason for retesting."
              :rules="[v => !!v || 'This field is required']"
              clearable
              outlined
              counter
              no-resize
              dense
            />
          </v-col>
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
import snackbarPlugin from '@/plugins/snackbar'
import PentestService from '@/services/pentest.service'

export default {
  components: {
    BaseDialog
  },
  props: ['showRetestDialog', 'items', 'editedItem', 'isSecMethods'],
  data() {
    return {
      valid: true,
      comments: null,
      menuStartDate: false,
      menuEndDate: false,
      retestStartDate: null,
      retestEndDate: null
    }
  },
  computed: {
    minDate() {
      const today = new Date()
      return this.formatDate(today)
    }
  },
  methods: {
    close() {
      this.$emit('update:show-retest-dialog', false)
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.isSecMethods) {
          snackbarPlugin.showSuccess('The retest of the pentest has been successfully requested!')
          PentestService.postRetest(
            this.$route.params.id, { comments: this.comments, startDate: this.retestStartDate, endDate: this.retestEndDate }
          ).then(
            () => { this.$emit('reload-items') }
          )
          this.close()
        }
        if (!this.isSecMethods) {
          snackbarPlugin.showSuccess(`Pentest (${this.editedItem.title}) updated!`)
          PentestService.postRetest(
            this.editedItem.id, { comments: this.comments, startDate: this.retestStartDate, endDate: this.retestEndDate }
          ).then(
            () => { this.$emit('reload-items') }
          )
          this.close()
        }
      }
      if (!this.$refs.form.validate()) {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    },
    formatDate(date) {
      let month = `${date.getMonth() + 1}`
      let day = `${date.getDate()}`
      const year = date.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    }
  }
}
</script>
