<template>
  <v-row justify="center">
    <v-dialog
      :value="value"
      max-width="800px"
      transition="dialog-bottom-transition"
      @click:outside="closeModal"
      @input="$emit('input', $event)"
    >
      <v-card :elevation="0">
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>
            <v-icon
              large
              color="white"
              class="text-36 mr-4"
            >
              mdi-rocket-launch-outline
            </v-icon>
            Schedule a Pentest
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click.native="closeModal"
              @keyup="closeModal"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-stepper
          v-model="stepperStep"
          alt-labels
        >
          <v-stepper-header>
            <v-stepper-step
              color="success"
              :complete="stepperStep > 1"
              step="1"
              :elevation="0"
            >
              <div class="text-center">
                <h5 class="my-0">Asset</h5>
                <small>Select your asset</small>
              </div>
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              v-if="assetGroup === 'create'"
              color="success"
              :complete="stepperStep > 1.5"
              step="1.5"
              :elevation="0"
            >
              <div class="text-center">
                <h5 class="my-0">New Asset</h5>
                <small>Create your asset</small>
              </div>
            </v-stepper-step>

            <v-divider v-if="assetGroup === 'create'" />

            <v-stepper-step
              color="success"
              :complete="stepperStep > 2"
              step="2"
            >
              <div class="text-center">
                <h5 class="my-0">Scope</h5>
                <small>Select testing type and provide details</small>
              </div>
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              color="success"
              :complete="stepperStep > 3"
              step="3"
            >
              <div class="text-center">
                <h5 class="my-0">Timeline</h5>
                <small>Schedule your test</small>
              </div>
            </v-stepper-step>

            <v-divider />

            <v-stepper-step step="4" color="success">
              <div class="text-center">
                <h5 class="my-0">Review</h5>
                <small>Review & Kick-off</small>
              </div>
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              step="1"
              class="pt-0 mt-0"
            >
              <div class="my-2 px-6 ul-icon-box-animate-onhover">
                <div class="ul-icon-box mx-auto mb-3 text-center">
                  <v-img
                    height="80"
                    width="80"
                    class="mx-auto"
                    src="@/assets/images/svg/startup.svg"
                  />
                </div>
                <div class="text-center">
                  <h4 class="font-weight-bold mb-0">Let's kick off!</h4>
                  <p class="text--lighten-1 py-0 my-0">
                    You need to create/select an asset
                  </p>
                </div>
                <v-form
                  ref="formStep1"
                  v-model="validS1"
                  class="mt-2"
                  lazy-validation
                >
                  <v-row v-if="isSuperUser" class="mt-0 pt-0 justify-center">
                    <v-col class="pt-0">
                      <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                        CLIENT <span class="h6 primary--text text--lighten-2">*</span>
                      </div>
                      <async-search-select
                        v-model="client"
                        placeholder="Select a client"
                        :items="itemsClient"
                        item-text="name"
                        :items-update="fetchClientRefs"
                        :rules="[v => (!isSuperUser || !!v) || 'This field is required']"
                        base-style=""
                        :style-extra="{ dense: true, flat: true, outlined: true, 'hide-details': false}"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 justify-center">
                    <v-col
                      cols="12"
                      class="mt-0 pt-0"
                    >
                      <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                        PENTEST TYPE <span class="h6 primary--text text--lighten-2">*</span>
                      </div>
                      <v-select
                        v-model="pentestTypes"
                        :rules="[v => !!v || 'This field is required']"
                        :items="pentestType"
                        placeholder="Define pentest type"
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 justify-center">
                    <v-col
                      cols="12"
                      class="mt-0 pt-0"
                    >
                      <v-radio-group
                        v-model="assetGroup"
                        class="justify-center mt-n3"
                      >
                        <div class="d-flex align-start">
                          <v-radio
                            label="Select an existing asset"
                            value="existing"
                          />
                          <v-radio
                            label="Create new asset"
                            value="create"
                            class="ml-4"
                            @click="resetAssetSelected"
                          />
                        </div>
                        <async-search-select
                          v-show="assetGroup === 'existing'"
                          ref="assetSearch"
                          v-model="asset"
                          placeholder="Select your assets"
                          :items="itemsAsset"
                          item-text="name"
                          :items-update="fetchAssets"
                          :rules="assetGroup === 'create' ? [] : [v => !!v || 'This field is required']"
                          :is-disabled="isSuperUser && client === null"
                          base-style=""
                          :style-extra="{ dense: true, flat: true, outlined: true, 'hide-details': false}"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-form>
              </div>

              <div class="text-right mr-6">
                <v-btn
                  color="primary"
                  @click="assetGroup === 'create' ? nextStepValidated(1.5) : nextStepValidated(2)"
                >
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content
              step="1.5"
              class="pt-0 mt-0"
            >
              <v-card
                class="mb-12 mx-6 removeBG"
                :elevation="0"
              >
                <v-form
                  ref="formStep15"
                  v-model="validS15"
                  lazy-validation
                >
                  <v-row class="mt-0 pt-0 justify-center">
                    <v-col
                      cols="12"
                      class="mt-0 pt-0"
                    >
                      <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                        ASSET NAME <span class="h6 primary--text text--lighten-2">*</span>
                      </div>
                      <v-text-field
                        v-model="assetName"
                        :rules="[v => !!v || 'This field is required']"
                        placeholder="Define asset name"
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 justify-center">
                    <v-col
                      cols="12"
                      class="mt-0 pt-0"
                    >
                      <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                        ASSET TYPE <span class="h6 primary--text text--lighten-2">*</span>
                      </div>
                      <v-select
                        v-model="assetType"
                        :rules="[v => !!v || 'This field is required']"
                        :items="assetTypes"
                        placeholder="Define asset type"
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 justify-center">
                    <v-col
                      cols="12"
                      class="mt-0 pt-0"
                    >
                      <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                        TECHNOLOGY <small class="font-italic font-weight-thin">(Optional)</small>
                      </div>
                      <v-combobox
                        v-model="assetSelectedTechno"
                        :items="assetTechnos"
                        :rules="[v => !!v || 'This field is required']"
                        placeholder="Select/Unselect your technologie"
                        multiple
                        danse
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 justify-center">
                    <v-col
                      cols="12"
                      class="mt-0 pt-0"
                    >
                      <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                        DESCRIPTION <small class="font-italic font-weight-thin">(Optional)</small>
                      </div>
                      <v-textarea
                        v-model="assetDescription"
                        placeholder="Add a description"
                        value=""
                        clearable
                        no-resize
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>

              <div class="text-right mt-n10 mr-6">
                <v-btn
                  color="primary"
                  outlined
                  class="mr-3"
                  @click="stepperStep = 1"
                >
                  Back
                </v-btn>
                <v-btn
                  color="primary"
                  @click="nextStepValidated(2)"
                >
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content
              step="2"
              class="pt-0 mt-0"
            >
              <v-card
                class="mb-12 mx-6 removeBG"
                :elevation="0"
              >
                <v-form
                  ref="formStep2"
                  v-model="validS2"
                  lazy-validation
                >
                  <v-row class="mt-0 pt-0 justify-center">
                    <v-col
                      cols="12"
                      class="mt-0 pt-0"
                    >
                      <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                        TARGETS <small class="font-italic font-weight-thin">(Optional)</small>
                      </div>
                      <v-combobox
                        v-model="pentestSelectedTargets"
                        :items="pentestTargets"
                        :rules="[v => !!v || 'This field is required']"
                        placeholder="Select/Unselect your targets"
                        multiple
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 justify-center">
                    <v-col
                      cols="12"
                      class="pt-0"
                    >
                      <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                        PENTEST TITLE <span class="h6 primary--text text--lighten-2">*</span>
                      </div>
                      <v-text-field
                        v-model="pentestTitle"
                        :rules="[v => !!v || 'This field is required']"
                        placeholder="Give your project a title"
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 justify-center">
                    <v-col
                      cols="12"
                      class="mt-0 pt-0"
                    >
                      <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                        SCOPE OF WORK <span class="h6 primary--text text--lighten-2">*</span>
                      </div>
                      <v-textarea
                        v-model="pentestNotes"
                        placeholder="Add any precision or specific requirements"
                        :rules="[v => !!v || 'This field is required']"
                        value=""
                        clearable
                        no-resize
                        auto-grow
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                  <!--<v-row class="mt-0 pt-0 justify-center">
                    <v-col
                      cols="12"
                      md="8"
                      lg="6"
                      class="mt-0 pt-0"
                    >
                      <div class="text-left primary--text font-weight-bold">
                        CREDENTIALS <small class="font-italic font-weight-thin">(Optional)</small>
                      </div>>
                      <v-textarea
                        v-model="pentestCredentials"
                        placeholder="Provide credentials if required"
                        value=""
                        clearable
                        no-resize
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>-->
                </v-form>
              </v-card>
              <div class="text-right mt-n10 mr-6">
                <v-btn
                  color="primary"
                  outlined
                  class="mr-3"
                  @click="assetGroup === 'create' ? stepperStep = 1.5 : stepperStep = 1"
                >
                  Back
                </v-btn>
                <v-btn
                  color="primary"
                  @click="nextStepValidated(3)"
                >
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content
              step="3"
              class="pt-0 mt-0"
            >
              <v-card
                class="mb-12 mx-6 removeBG"
                :elevation="0"
              >
                <v-form
                  ref="formStep3"
                  v-model="validS3"
                  lazy-validation
                >
                  <v-row class="mt-0 pt-0">
                    <v-col
                      cols="12"
                      class="mt-0 pt-0"
                    >
                      <div class="primary--text text--lighten-2 font-weight-bold mb-n2">
                        TIMELINE <small class="font-italic font-weight-thin">(Optional)</small>
                      </div>
                      <v-row class="mt-0 pt-0">
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-menu
                            v-model="menuStartDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="pentestStartDate"
                                label="Expected start date"
                                readonly
                                outlined
                                dense
                                clearable
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="pentestStartDate"
                              :min="minDate"
                              @input="menuStartDate = false"
                            />
                          </v-menu>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-menu
                            v-model="menuEndDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="pentestEndDate"
                                label="Expected end date"
                                readonly
                                dense
                                outlined
                                clearable
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="pentestEndDate"
                              :min="minDateEnd"
                              @input="menuEndDate = false"
                            />
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0 pt-0">
                    <v-col
                      cols="12"
                      class="mt-0 pt-0"
                    >
                      <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                        RESTRICTIONS <small class="font-italic font-weight-thin">(Optional)</small>
                      </div>
                      <v-textarea
                        v-model="pentestRestrictions"
                        placeholder="Any time restrictions? Please specify; (migration, expected downtime, new deployment, etc.)"
                        value=""
                        clearable
                        no-resize
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>

                  <v-row class="mt-0 pt-0">
                    <v-col
                      cols="12"
                      class="mt-0 pt-0"
                    >
                      <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                        UPLOAD FILE <small class="font-italic font-weight-thin">(Optional)</small>
                      </div>
                      <v-file-input
                        v-model="pentestFile"
                        label="File input"
                        outlined
                        chips
                        counter
                        show-size
                        clearable
                        truncate-length="15"
                        dense
                        @change="uploadFile"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>

              <div class="text-right mt-n10 mr-6">
                <v-btn
                  color="primary"
                  outlined
                  class="mr-3"
                  @click="stepperStep = 2"
                >
                  Back
                </v-btn>

                <v-btn
                  color="primary"
                  @click="nextStepValidated(4)"
                >
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content
              step="4"
              class="pt-0 mt-0"
            >
              <v-card
                class="mb-3 mt-0 px-6 removeBG"
                :elevation="0"
              >
                <v-row dense>
                  <v-col
                    cols="12"
                    lg="12"
                    md="12"
                    class="mt-0 pt-0"
                  >
                    <div class="mt-0">
                      <div class="ul-icon-box mx-auto">
                        <v-img
                          height="100"
                          width="100"
                          class="mx-auto"
                          src="@/assets/images/svg/timeline.svg"
                        />
                      </div>
                      <div class="text-center">
                        <h4 class="font-weight-bold mb-0">Review your project</h4>
                        <p class="text--lighten-1">
                          One step away from kick-off!
                        </p>
                      </div>
                      <span v-if="assetGroup === 'create'">
                        <v-divider class="my-4" />
                        <v-row dense>
                          <v-col cols="12">
                            <h5 class="mt-0 pt-0">New asset</h5>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" md="3">
                            <h6 class="my-1 text-18 mr-2">Asset name :</h6>
                          </v-col>
                          <v-col cols="9">
                            <p class="mb-1 text-18">
                              {{ assetName }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" md="3">
                            <h6 class="my-1 text-18 mr-2">Asset Type :</h6>
                          </v-col>
                          <v-col cols="9">
                            <p class="mb-1 text-18">
                              {{ assetType }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" md="3">
                            <h6 class="my-1 text-18 mr-2">Technology :</h6>
                          </v-col>
                          <v-col cols="9">
                            <p
                              v-if="pentestSelectedTargets.length"
                              class="mb-1 text-18"
                            >
                              <v-chip
                                v-for="techno in assetSelectedTechno"
                                :key="techno"
                                pill
                                color="light"
                                class="mr-2"
                                small
                              >
                                {{ techno }}
                              </v-chip>
                            </p>
                            <p
                              v-else
                              class="mb-1 text-18"
                            >
                              <v-chip
                                pill
                                color="warning"
                                class="mr-2"
                                small
                              >
                                No Target
                              </v-chip>
                            </p>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" md="3">
                            <h6 class="my-1 text-18">Asset Description :</h6>
                          </v-col>
                          <v-col cols="9">
                            <p v-if="assetDescription" class="mb-1 text-18">
                              {{ assetDescription }}
                            </p>
                            <p
                              v-else
                              class="mb-1 text-18"
                            >
                              <v-chip
                                pill
                                color="warning"
                                class="mr-2"
                                small
                              >
                                No Description
                              </v-chip>
                            </p>
                          </v-col>
                        </v-row>
                      </span>

                      <v-divider class="my-4" />

                      <v-row dense>
                        <v-col cols="12">
                          <h5 class="mt-0 pt-0">Scope of work</h5>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" md="3">
                          <h6 class="my-1 mr-2">Pentest name :</h6>
                        </v-col>
                        <v-col cols="9">
                          <p class="mb-1 text-18">
                            {{ pentestTitle }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" md="3">
                          <h6 class="my-1 mr-2">Targets :</h6>
                        </v-col>
                        <v-col cols="9">
                          <p
                            v-if="pentestSelectedTargets.length"
                            class="mb-1 text-18"
                          >
                            <v-chip
                              v-for="target in pentestSelectedTargets"
                              :key="target"
                              pill
                              color="light"
                              class="mr-2"
                              small
                            >
                              {{ target }}
                            </v-chip>
                          </p>
                          <p
                            v-else
                            class="mb-1 text-18"
                          >
                            <v-chip
                              pill
                              color="warning"
                              class="mr-2"
                              small
                            >
                              No Target
                            </v-chip>
                          </p>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" md="3">
                          <h6 class="my-1 mr-2">Pentest type :</h6>
                        </v-col>
                        <v-col cols="9">
                          <p class="mb-1 text-18 text-capitalize">
                            {{ pentestTypes }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" md="3">
                          <h6 class="my-1 mr-2">Scope of Work :</h6>
                        </v-col>
                        <v-col cols="9">
                          <p
                            v-if="pentestNotes"
                            class="mb-1 text-18"
                            style="white-space: pre-line"
                          >
                            {{ pentestNotes }}
                          </p>
                          <p
                            v-else
                            class="mb-1 text-18"
                            style="white-space: pre-line"
                          >
                            <v-chip
                              pill
                              color="warning"
                              class="mr-2"
                              small
                            >
                              No Notes
                            </v-chip>
                          </p>
                        </v-col>
                      </v-row>
                      <!--<v-row dense>
                        <v-col cols="12" md="3">
                          <h6 class="my-1 mr-2">Credentials :</h6>
                        </v-col>
                        <v-col cols="9">
                          <p
                            v-if="pentestCredentials"
                            class="mb-1 text-18"
                            style="white-space: pre-line"
                          >
                            {{ pentestCredentials }}
                          </p>
                          <p
                            v-else
                            class="mb-1 text-18"
                            style="white-space: pre-line"
                          >
                            <v-chip
                              pill
                              color="warning"
                              class="mr-2"
                              small
                            >
                              No Credentials
                            </v-chip>
                          </p>
                        </v-col>
                      </v-row>-->
                      <v-row dense>
                        <v-col cols="12" md="3">
                          <h6 class="my-1 mr-2">Upload file :</h6>
                        </v-col>
                        <v-col cols="9">
                          <p
                            v-if="pentestFile"
                            class="mb-1 text-18"
                            style="white-space: pre-line"
                          >
                            {{ pentestFile.name }}
                          </p>
                          <p
                            v-else
                            class="mb-1 text-18"
                            style="white-space: pre-line"
                          >
                            <v-chip
                              pill
                              color="warning"
                              class="mr-2"
                              small
                            >
                              No File
                            </v-chip>
                          </p>
                        </v-col>
                      </v-row>
                      <v-divider class="my-4" />
                      <v-row dense>
                        <v-col cols="12">
                          <h5 class="mt-0 pt-2">Timeline</h5>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" md="3">
                          <h6 class="my-1 mr-2">Start date :</h6>
                        </v-col>
                        <v-col cols="9">
                          <p
                            class="mb-1 text-18"
                            style="white-space: pre-line"
                          >
                            {{ pentestStartDate }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" md="3">
                          <h6 class="my-1 mr-2">End date :</h6>
                        </v-col>
                        <v-col cols="9">
                          <p
                            v-if="pentestEndDate"
                            class="mb-1 text-18"
                            style="white-space: pre-line"
                          >
                            {{ pentestEndDate }}
                          </p>
                          <p
                            v-else
                            class="mb-1 text-18"
                            style="white-space: pre-line"
                          >
                            <v-chip
                              pill
                              color="warning"
                              class="mr-2"
                              small
                            >
                              No End date
                            </v-chip>
                          </p>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" md="3">
                          <h6 class="my-1 mr-2">Restriction :</h6>
                        </v-col>
                        <v-col cols="9">
                          <p
                            v-if="pentestRestrictions"
                            class="mb-1 text-18"
                            style="white-space: pre-line"
                          >
                            {{ pentestRestrictions }}
                          </p>
                          <p
                            v-else
                            class="mb-1 text-18"
                            style="white-space: pre-line"
                          >
                            <v-chip
                              pill
                              color="warning"
                              class="mr-2"
                              small
                            >
                              No Restriction
                            </v-chip>
                          </p>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-card>

              <div class="text-right mt-5 mr-6">
                <v-btn
                  color="primary"
                  outlined
                  class="mr-3"
                  @click="stepperStep = 3"
                >
                  Back
                </v-btn>

                <v-btn
                  color="primary"
                  @click="submitData"
                >
                  Submit
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { pentestType, assetTypes, assetTechnos } from '@/data/common'
import AssetService from '@/services/asset.service'
import PentestService from '@/services/pentest.service'
import ClientService from '@/services/client.service.js'
import snackbarPlugin from '@/plugins/snackbar'
import FilesService from '@/services/files.service.js'
import Templates from '../../../views/Templates.vue'
import UserService from '@/services/user.service'
import AsyncSearchSelect from '@/components/base/AsyncSearchSelect.vue'

export default {
  components: { AsyncSearchSelect, Templates },
  props: {
    value: Boolean
  },
  data: () => ({
    itemsAsset: [],
    stepperStep: 1,

    assetGroup: 'existing',
    asset: null,
    assetName: null,
    assetType: null,
    assetTypes,
    assetTechnos,
    assetSelectedTechno: [],
    assetDescription: null,

    pentestType,
    pentestSelectedTargets: [],
    menuStartDate: false,
    menuEndDate: false,
    pentestTitle: 'Penetration test',
    pentestTypes: '',
    pentestNotes: '',
    pentestCredentials: '',
    pentestStartDate: '',
    pentestEndDate: null,
    pentestRestrictions: '',
    pentestFile: null,
    fileId: null,
    pentestTargets: [],

    client: null,
    itemsClient: [],
    isSuperUser: false,

    validS1: true,
    validS15: true,
    validS2: true,
    validS3: true,

    isLoadindA: false
  }),
  computed: {
    minDate() {
      const today = new Date()
      return this.formatDate(today)
    },
    minDateEnd() {
      const today = new Date(Date.now() + 691200000)
      return this.formatDate(today)
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isSuperUser = profile.role === 'Superuser'
    }

    this.pentestStartDate = this.minDate
    this.pentestEndDate = this.minDateEnd
  },
  watch: {
    pentestTypes: function(newValue, oldValue) {
      this.updatePentestTitle()
    },
    client: {
      handler() {
        if (this.client !== null) {
          this.fetchAssets('')
        } else {
          this.asset = null
        }
      }
    }
  },
  methods: {
    formatDate(date) {
      let month = `${date.getMonth() + 1}`
      let day = `${date.getDate()}`
      const year = date.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
    updatePentestTitle() {
      for (let i = 0; i < pentestType.length; i++) {
        if (pentestType[i].value === this.pentestTypes) {
          this.pentestTitle = `${pentestType[i].text} Penetration Test`
        }
      }
    },
    async fetchClientRefs(search) {
      ClientService.getClientRefs(1, 10, search).then((resp) => {
        this.itemsClient = resp.results
      })
    },
    nextStepValidated(step) {
      if (step === 1.5 && this.assetGroup === 'create') { // Step 1 for new asset
        if (this.$refs.formStep1.validate()) {
          this.stepperStep = step
        } else {
          snackbarPlugin.showError('Oops, complete the required fields!')
        }
      }
      if (step === 2) {
        if (this.assetGroup === 'existing') { // Step 1 with existing asset
          if (this.$refs.formStep1.validate()) {
            // set fields populated by the existing asset
            this.setAssetFields()
            this.stepperStep = step
          } else {
            snackbarPlugin.showError('Oops, complete the required fields!')
          }
        }
        if (this.assetGroup === 'create') { // Step 1.5 (create asset)
          if (this.$refs.formStep15.validate()) {
            this.stepperStep = step
          } else {
            snackbarPlugin.showError('Oops, complete the required fields!')
          }
        }
      }
      if (step === 3) { // Step 2
        if (this.$refs.formStep2.validate()) {
          this.stepperStep = step
        } else {
          snackbarPlugin.showError('Oops, complete the required fields!')
        }
      }
      if (step === 4) { // Step 3
        if (this.$refs.formStep3.validate()) {
          this.stepperStep = step
        } else {
          snackbarPlugin.showError('Oops, complete the required fields!')
        }
      }
    },
    resetAssetSelected() {
      this.pentestTargets = []
      this.pentestSelectedTargets = []
    },
    async setAssetFields() {
      this.pentestTargets = this.asset.targets
      this.pentestSelectedTargets = this.pentestTargets
    },
    async uploadFile() {
      const res = await FilesService.postUploadFile([this.pentestFile])
      snackbarPlugin.showSuccess(`File ${this.pentestFile.name} uploaded!`)
      this.fileId = res.uuid
    },
    async fetchAssets(search) {
      if (this.isSuperUser && !this.client) {
        this.itemsAsset = []
        return
      }
      const filters = this.isSuperUser && this.client ? { clientID: this.client.id } : {}
      const response = await AssetService.getAssets(1, 10, search, filters)
      this.itemsAsset = response.results
    },
    resetForm() {
      this.$refs.formStep1.reset()
      this.$refs.formStep15.reset()
      this.$refs.formStep2.reset()
    },
    async closeModal() {
      if (this.pentestFile !== null) {
        this.fileId = null
        this.pentestFile = null
        this.resetForm()
      }
      this.$emit('input', false)
      this.resetForm()
      this.stepperStep = 1
    },
    async submitData() {
      let assetId
      snackbarPlugin.showLoading('Creating ...')
      if (this.assetGroup === 'existing') {
        assetId = this.asset.id
      } else if (this.assetGroup === 'create') {
        const asset = {
          name: this.assetName,
          type: this.assetType,
          technos: this.assetSelectedTechno,
          description: this.assetDescription,
          targets: this.pentestSelectedTargets,
          hasInternetAccess: true,
          icon: 'globe'
        }
        if (this.isSuperUser) {
          asset.clientId = this.client.id
        }
        const resAsset = await AssetService.postAsset(asset)
        assetId = resAsset.id
      }
      const pentest = {
        title: this.pentestTitle,
        language: 'en',
        type: this.pentestTypes,
        startDate: this.pentestStartDate,
        endDate: this.pentestEndDate,
        targetIsPublic: 'true',
        scope: this.pentestSelectedTargets,
        description: '',
        scopeOfWork: `${this.pentestNotes} ${this.pentestRestrictions}`,
        assetId: assetId
      }
      if (this.isSuperUser) {
        pentest.clientId = this.client.id
      }
      const resP = await PentestService.postPentests(pentest)
      snackbarPlugin.showSuccess(`Pentest ${this.pentestTitle} Created!`)
      this.$swal({
        text: "You've just created a pentest",
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok, got it!',
        customClass: {
          confirmButton: 'btn fw-bold btn-light-primary'
        }
      })
      if (this.pentestFile !== null) {
        await FilesService.postAddFileByPentest(resP.id, this.fileId)
      }
      this.stepperStep = 1
      this.$emit('input', false)
      this.$store.dispatch('initializeData/refreshData', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-stepper__step__step {
  height: 36px !important;
  width: 36px;
  font-size: 1.3rem;
}

.v-stepper__header {
  box-shadow: none;
  background-color: transparent !important;
}
.removeBG {
  background-color: transparent !important;
}
</style>
