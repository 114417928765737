<template>
  <base-dialog
    :show="showSchedulePentestDialog"
    formTitle="Purchase a Subscription"
    size="550px"
    hideDefaultBtn="false"
    icon="mdi-shopping-outline"
    textBtn2="Schedule"
    v-on:close="close"
    v-on:save="save"
  >
    <template v-slot:form>
      <div class="text-center mb-6">
        <h1 style="font-size:42px" class="font-weight-bold error--text">0</h1>
        <h5>Subscription available</h5>
      </div>
      <div class="text-center">You're running out of subscription...</div>
      <p class="text-center">Please purchase a new subscription and schedule your next pentest!</p>
      <div class="text-center mt-8 mb-n4">
        <v-btn outlined @click="goTo"><v-icon class="mr-2" color="#0067b8">mdi-microsoft-azure</v-icon> Azure Marketplace</v-btn>
      </div>
    </template>
  </base-dialog>
</template>

<script>
  import BaseDialog from "@/components/dialog/BaseDialog";

  export default {
    components: {
      BaseDialog,
    },
    props: ["showSchedulePentestDialog", 'subscriptionsPlans'],
    data: () => ({

    }),
    mounted () {
    },
    methods: {
      goTo () {
        if (confirm('You will be redirected to an external page of this website at: https://azuremarketplace.microsoft.com/fr-ca/marketplace/apps/vumetricsolutionsinc1664228210542.ptaas?tab=Overview') === true) {
          window.location.href = 'https://azuremarketplace.microsoft.com/fr-ca/marketplace/apps/vumetricsolutionsinc1664228210542.ptaas?tab=Overview'
        }
      },
      async save () {
      },
      close () {
        this.$emit("update:showSchedulePentestDialog", false);
      }
    }
  };
</script>
